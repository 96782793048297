<template>
  <div class="myharting-page">
    <Nav />
    <div class="harting-top">
      <img src="../../image/harting-img01.png" alt="" />
    </div>
    <div class="harting-title">
      <h1 class="title">合作关系的新家园</h1>
      <p class="text">myHARTING。访问我们新的数字项目暨合作关系平台。</p>
      <div class="icon-box">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.3536 16.3536C16.4015 16.3056 16.4377 16.2504 16.4621 16.1914C16.4861 16.1333 16.4996 16.0697 16.5 16.003L16.5 16V15.9995V8C16.5 7.72386 16.2761 7.5 16 7.5C15.7239 7.5 15.5 7.72386 15.5 8V14.7929L8.35355 7.64645C8.15829 7.45118 7.84171 7.45118 7.64645 7.64645C7.45118 7.84171 7.45118 8.15829 7.64645 8.35355L14.7929 15.5H8C7.72386 15.5 7.5 15.7239 7.5 16C7.5 16.2761 7.72386 16.5 8 16.5H15.9995H16C16.008 16.5 16.016 16.4998 16.024 16.4994C16.0811 16.4967 16.1378 16.4842 16.1914 16.4621C16.2504 16.4377 16.3056 16.4015 16.3536 16.3536Z"
            fill="white"
            fill-opacity="0.7"
          />
        </svg>
      </div>
    </div>
    <div class="harting-main">
      <span>myHARTING</span> – 使协作和工作流程更简单、更透明。
      在浩亭，了解我们的客户和合作伙伴所关心的事项非常重要。而现在，我们也希望以数字方式来增进这种了解。
    </div>
    <div class="btn-wrap">
      <div class="btn-link">
        <a href="https://baidu.com">去官网看看</a>
      </div>
    </div>
    <Footer class="custom-footer" />
    <div class="empty"></div>
  </div>
</template>
<script>
import Footer from "../../component/footer.vue";
import Nav from "../../component/nav.vue";
export default {
  name: "MYHARTING",
  components: {
    Nav,
    Footer,
  },
};
</script>
<style lang="less" scpoed>
.myharting-page {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 80px;
  .harting-top {
    img {
      display: block;
      width: 100%;
      height: 160px;
      object-fit: cover;
    }
  }
  .harting-title {
    h1 {
      text-align: center;
      margin-bottom: 8px;
      color: #212027;
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 48px */
    }
    .text {
      padding: 0 32px;
      color: #535359;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
    }
    .icon-box {
      margin-bottom: 8px;
      padding: 0 32px;
    }
  }
  .harting-main {
    margin-bottom: 32px;
    padding: 0 32px;
    color: #212027;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    span {
      font-weight: 600;
    }
  }
  .btn-wrap {
    padding: 0 32px;
    .btn-link {
      box-sizing: border-box;
      width: 160px;
      height: 40px;
      padding: 12px 24px;
      border-radius: 40px;
      background: @theme-color;
      color: #212027;
      font-size: 16px;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 19.2px */
    }
  }
 .custom-footer{
    position: fixed;
    left: 0;
    bottom: 54px;
 }
}
</style>
